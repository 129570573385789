import * as React from "react";
import { useLocation } from "@reach/router";
import { clsx } from "clsx";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import SearchResults from "../components/SearchResults/SearchResults";
import SearchResult from "../components/SearchResult/SearchResult";
import Button from "../components/Button/Button";
import SearchResultCarousel from "../components/SearchResultCarousel/SearchResultCarousel";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";

import "./ActivityPage.css";
import {
  getCategoriesByCountry as apiGetCategoriesByCountry,
  getActivitiesByCountry as apiGetActivitiesByCountry,
} from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

const CountryPage = ({ location, pageContext }) => {
  const isFirstRender = React.useRef(true);

  const context = React.useContext(GlobalStateContext);

  const params = new URLSearchParams(location.search);
  const category = params.get("category");

  const [expanded, setExpanded] = React.useState(false);

  const [countryActivities, setCountryActivities] = React.useState([]);
  const [loadingCountryActivities, setLoadingCountryActivities] =
    React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loadingCategories, setLoadingCategories] = React.useState(false);

  const [selectedRootCategory, setSelectedRootCategory] = React.useState(null);
  const [filterCategories, setFilterCategories] = React.useState(
    category ? [category] : []
  );
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(450);

  const { country, rootCategories } = pageContext;

  let reloadTimerId = null;

  const getCategoriesByCountry = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCategories(true);

      const data = await apiGetCategoriesByCountry(
        country.id,
        context.currentCurrency,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setCategories(data);
      }
      setLoadingCategories(false);
    } catch (e) {
      console.log(e);
      setLoadingCategories(false);
    }
  };

  const getActivitiesByCountry = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCountryActivities(true);

      const filterCategoriesStr = filterCategories
        .map((cat) => encodeURIComponent(cat))
        .join(",");

      const data = await apiGetActivitiesByCountry(
        country.id,
        context.currentCurrency,
        filterCategoriesStr,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setCountryActivities(data);
      }
      setLoadingCountryActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCountryActivities(false);
    }
  };

  React.useEffect(() => {
    getActivitiesByCountry();
  }, [context.currentCurrency, filterCategories]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      // Set isFirstRender to false after the first render
      isFirstRender.current = false;
      return;
    }

    const handleTimeout = () => {
      getActivitiesByCountry();
      getCategoriesByCountry();
      reloadTimerId = null;
    };

    if (reloadTimerId) {
      clearTimeout(reloadTimerId);
    }

    reloadTimerId = setTimeout(handleTimeout, 750);

    return () => {
      clearTimeout(reloadTimerId);
    };
  }, [searchTerm, minPrice, maxPrice]);

  React.useEffect(() => {
    getCategoriesByCountry();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    getActivitiesByCountry();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loadingCategories, loadingCountryActivities]);

  return (
    <Page>
      <TopBar showSearch={true} />
      {country.name && (
        <Section
          style={{
            color: "#fff",
            lineHeight: "30px",
          }}
        >
          <SectionHeading>{country.name}</SectionHeading>
          {country.description && (
            <div>
              <div
                className={clsx("activity-description", {
                  expanded,
                })}
              >
                {country.description}
              </div>
              <Button secondary compact onClick={() => setExpanded(!expanded)}>
                {expanded ? "Read less" : "Read more"}
              </Button>
            </div>
          )}
        </Section>
      )}
      {/* {foodTourExperienceActivities &&
        foodTourExperienceActivities.length > 0 && (
          <Section backgroundColor="#f5f5f5">
            <SectionHeading component="h2">
              {country.name.toUpperCase()} FOOD TOURS & EXPERIENCES
            </SectionHeading>
            <SearchResultCarousel
              slides={foodTourExperienceActivities.map((activity) => (
                <SearchResult compact={true} searchResult={activity} />
              ))}
            />
          </Section>
        )} */}
      <Section backgroundColor="#F1F7FA">
        {countryActivities && (
          <SearchResults
            categories={categories}
            selectedRootCategory={selectedRootCategory}
            setSelectedRootCategory={setSelectedRootCategory}
            filterCategories={filterCategories}
            setFilterCategories={setFilterCategories}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            searchResults={countryActivities}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            loading={loadingCategories || loadingCountryActivities}
          />
        )}
      </Section>
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default CountryPage;

export const Head = ({ pageContext }) => {
  const { country } = pageContext;
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={`${country.name} | Travel Lingual`}
      description={country.description}
      pathname={location.pathname}
    />
  );
};
